import {atom, selector} from 'recoil';

export const emotionBranch = atom({
	key: 'emotionBranchState',
	default: ''
});

export const storeSelected = atom({
	key: 'storeSelectedState',
	default: {
		classKey: "",
		className: "",
		kidKey: "",
		kidName: "",
		attendanceKey: "",
		attendanceName: "",
		emotionKey: "",
		emotionName: "",
	}
});

export const modalControl = atom({
	key: 'modalControlState',
	default: false
});

export const apiToken = atom({
	key: 'apiToken',
	default: ''
});

export const getfetchResult = atom({
	key: 'getfetchResult',
	default: 200
});

export const getKindergartensData = atom({
	key: 'getKindergartensData',
	default: {}
});

export const getClassesData = atom({
	key: 'getClassesData',
	default: null
});

export const getKidsData = atom({
	key: 'getKidsData',
	default: null
});

export const getAttendanceData = atom({
	key: 'getAttendanceData',
	default: null
});

export const getAttendResult = atom({
	key: 'getAttendResult',
	default: ""
});

export const recordedAudio = atom({
	key: 'recordedAudio',
	default: new Audio()
});

export const skipAudioRecord = atom({
	key: 'skipAudioRecord',
	default: false
});

export const teacherLevelState = atom({
	key: 'teacherLevel',
	default: false
});

export const attandanceState = atom({
	key: 'attandanceState',
	default: ''
});

export const evaluationState = atom({
	key: 'evaluationState',
	default: null
});
