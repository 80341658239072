// Chakra imports
import {
	AvatarGroup,
	Avatar,
	Box,
	Button,
	Flex,
	Icon,
	Image,
	Link,
	Text,
	useColorModeValue, Center, SimpleGrid,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import React, {useEffect, useState} from "react";
import {IoHeart, IoHeartOutline} from "react-icons/io5";
import {useRecoilState} from "recoil";
import {emotionBranch, getfetchResult, modalControl, storeSelected} from "../../recoil/atom/atomStore";
import {useLocation} from "react-router-dom";
import ChakraModal from "../../views/admin/chakraModal";


export default function Attendance(props) {
	const {image, name, download, isAttend, isClassSelected, detailKey, emotion} = props;
	const [like, setLike] = useState(false);

	const [modal, setModal] = useRecoilState(modalControl);
	const [selectedState, setSelectedState] = useRecoilState(storeSelected);

	const token = localStorage.getItem("token");

	const [fetchResult, setFetchResult] = useRecoilState(getfetchResult);

	const nowlocation = useLocation().pathname;


	const handleOpen = () => {
		console.log("handleOpen");
		if (nowlocation === "/nulbom/attendance") {
			console.log("ATTENDANCE");
			setSelectedState({
				...selectedState,
				kidKey: detailKey,
				kidName: name,
			});
		} else if (nowlocation === "/nulbom/emotion-select") {
			console.log("EMOTION");
			setSelectedState({
				...selectedState,
				emotionKey: detailKey,
				emotionName: name,
			});
		}
		setModal(true);
	}

	useEffect(() => {
		console.log(selectedState);
	}, [selectedState]);

	const endEmotionSelect = () => {
		console.log("here")
		if (nowlocation === "/nulbom/emotion-select") {
			setSelectedState({
				...selectedState,
				emotionKey: detailKey,
				emotionName: name,
			});
			window.location.href = "/#/nulbom/voice-record";
		} else {
			setSelectedState({
				...selectedState,
				kidKey: detailKey,
				kidName: name,
			});
			window.location.href = "/#/nulbom/emotion-select"
			setModal(false);
		}
	}
	const teacherView = () => {

	}
	const textColor = useColorModeValue("navy.700", "white");
	const textColorBid = useColorModeValue("green.500", "white");

	return (
	  <Card p='20px'>
		  <ChakraModal props={props}/>
		  {/*<Link*/}
		  {/*href={download}*/}
		  {/*mt={{*/}
		  {/*	base: "0px",*/}
		  {/*	md: "10px",*/}
		  {/*	lg: "0px",*/}
		  {/*	xl: "10px",*/}
		  {/*	"2xl": "0px",*/}
		  {/*}}>*/}
		  <Flex direction={{base: "column"}} justify='center'
				height={'25vh'}
				onClick={
					nowlocation === "/nulbom/emotion-select" || nowlocation === "/nulbom/attendance" ? endEmotionSelect : handleOpen
				}
		  >
			  <Center>
				  <SimpleGrid columns={1} spacing={1}>
					  <Center>
						  {emotion}
					  </Center>
					  <Text
						align={"center"}
						color={textColor}
						fontSize='6xl'
						mb='5px'
						fontWeight='bold'
						me='14px'>
						  {name}
					  </Text>
				  </SimpleGrid>
			  </Center>
		  </Flex>
		  {/*</Link>*/}
	  </Card>
	);
}
