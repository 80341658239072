/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useEffect, useState} from "react";
import {NavLink, Redirect} from "react-router-dom";
// Chakra imports
import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import {HSeparator} from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import {FcGoogle} from "react-icons/fc";
import {MdOutlineRemoveRedEye} from "react-icons/md";
import {RiEyeCloseLine} from "react-icons/ri";
import {RecoilState, useRecoilState} from "recoil";
import {apiToken, getfetchResult} from "../../../recoil/atom/atomStore";
import PostFetchData from "../../../components/request/postFetchData";

function SignIn() {
	// Chakra color mode
	const textColor = useColorModeValue("navy.700", "white");
	const textColorSecondary = "gray.400";
	const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
	const textColorBrand = useColorModeValue("brand.500", "white");
	const brandStars = useColorModeValue("brand.500", "brand.400");
	const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
	const googleText = useColorModeValue("navy.700", "white");
	const googleHover = useColorModeValue(
	  {bg: "gray.200"},
	  {bg: "whiteAlpha.300"}
	);
	const googleActive = useColorModeValue(
	  {bg: "secondaryGray.300"},
	  {bg: "whiteAlpha.200"}
	);
	const [show, setShow] = useState(false);
	const [token, setToken] = useRecoilState(apiToken)
	const [fetchResult, setFetchResult] = useRecoilState(getfetchResult)
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [tryLogin, setTryLogin] = useState(false);
	const handleClick = () => setShow(!show);
	useEffect(() => {
		if (token !== '' && fetchResult === 'success') {
			localStorage
			  .setItem("token", token.data.Token);
			window.location.href = "/#/nulbom/dashboard";
		}
	}, [token, fetchResult]);

	// useEffect(() => {
	// 	console.log(fetchResult)
	// }, [fetchResult]);

	const fetchArgs = {
		fetchUrl: "/account/login/",
		fetchMethod: "POST",
		fetchHeader: {
			"Content-Type": "application/json",
		},
		fetchBody: JSON.stringify({
			"username": username,
			"password": password
		}),
		fetchDispatch: setToken,
		fetchResult: setFetchResult
	};

	const handleLogin = (fetchArgs) => {
		console.log(1)
		PostFetchData(fetchArgs)
		if (!tryLogin) {
			setTryLogin(true)
		}
	}
	return (
	  <DefaultAuth>
		  <Flex
			maxW={{base: "100%", md: "max-content"}}
			w='100%'
			mx={{base: "auto", lg: "0px"}}
			me='auto'
			h='100%'
			alignItems='start'
			justifyContent='center'
			mb={{base: "30px", md: "60px"}}
			px={{base: "25px", md: "0px"}}
			mt={{base: "40px", md: "14vh"}}
			flexDirection='column'>
			  <Box me='auto' mb={'4'}>
				  <Heading color={textColor} fontSize='36px' mb='10px'>
					  로그인
				  </Heading>
				  {/*<Text*/}
				  {/*  mb='36px'*/}
				  {/*  ms='4px'*/}
				  {/*  color={textColorSecondary}*/}
				  {/*  fontWeight='400'*/}
				  {/*  fontSize='md'>*/}
				  {/*  Enter your email and password to sign in!*/}
				  {/*</Text>*/}
			  </Box>
			  <Flex
				zIndex='2'
				direction='column'
				w={{base: "100%", md: "420px"}}
				maxW='100%'
				background='transparent'
				borderRadius='15px'
				mx={{base: "auto", lg: "unset"}}
				me='auto'
				mb={{base: "20px", md: "auto"}}>
				  {/*<Button*/}
				  {/*  fontSize='sm'*/}
				  {/*  me='0px'*/}
				  {/*  mb='26px'*/}
				  {/*  py='15px'*/}
				  {/*  h='50px'*/}
				  {/*  borderRadius='16px'*/}
				  {/*  bg={googleBg}*/}
				  {/*  color={googleText}*/}
				  {/*  fontWeight='500'*/}
				  {/*  _hover={googleHover}*/}
				  {/*  _active={googleActive}*/}
				  {/*  _focus={googleActive}>*/}
				  {/*  <Icon as={FcGoogle} w='20px' h='20px' me='10px' />*/}
				  {/*  Sign in with Google*/}
				  {/*</Button>*/}
				  {/*<Flex align='center' mb='25px'>*/}
				  {/*  <HSeparator />*/}
				  {/*  <Text color='gray.400' mx='14px'>*/}
				  {/*    or*/}
				  {/*  </Text>*/}
				  {/*  <HSeparator />*/}
				  {/*</Flex>*/}
				  <FormControl>
					  <FormLabel
						display='flex'
						ms='4px'
						fontSize='sm'
						fontWeight='500'
						color={textColor}
						mb='8px'>
						  ID<Text color={brandStars}>*</Text>
					  </FormLabel>
					  <Input
						onChange={(e) => setUsername(e.target.value)}
						isRequired={true}
						variant='auth'
						fontSize='sm'
						ms={{base: "0px", md: "0px"}}
						type='email'
						mb='24px'
						fontWeight='500'
						size='lg'
					  />
					  <FormLabel
						ms='4px'
						fontSize='sm'
						fontWeight='500'
						color={textColor}
						display='flex'>
						  Password<Text color={brandStars}>*</Text>
					  </FormLabel>
					  <InputGroup size='md'>
						  <Input
							onChange={(e) => setPassword(e.target.value)}
							onKeyPress={(e) => {
								{
									e.key === 'Enter' ?
									  handleLogin(fetchArgs) : console.log('')
								}
							}}
							isRequired={true}
							fontSize='sm'
							mb='24px'
							size='lg'
							type={show ? "text" : "password"}
							variant='auth'
						  />
						  <InputRightElement display='flex' alignItems='center' mt='4px'>
							  <Icon
								color={textColorSecondary}
								_hover={{cursor: "pointer"}}
								as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
								onClick={handleClick}
							  />
						  </InputRightElement>
					  </InputGroup>
					  <Button
						onClick={() => {
							{
								username !== '' && password !== '' ?
								  handleLogin(fetchArgs) : alert('아이디와 비밀번호를 입력해주세요')
							}
						}}
						fontSize='sm'
						variant='brand'
						fontWeight='500'
						w='100%'
						h='50'
						mb='24px'>
						  로그인 하기
					  </Button>
				  </FormControl>
				  <Flex justify={'flex-end'}>
					  {fetchResult === 'fail' ?
						<Text color={'red'}>
							아이디와 비밀번호를 확인 해 주세요
						</Text> : null
					  }
				  </Flex>
			  </Flex>
		  </Flex>
	  </DefaultAuth>
	);
}

export default SignIn;
