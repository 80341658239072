/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useCallback, useEffect, useRef} from "react";
import {useState} from "react";

// Chakra imports
import {
	Box,
	Flex,
	Grid,
	Text,
	useColorModeValue,
	SimpleGrid, Icon, Center, Button,
} from "@chakra-ui/react";

// Custom components
import Attendance from "components/card/Attendance";

// Assets
import {
	MdKeyboardVoice,
	MdOutlineKeyboardVoice,
	MdMicOff
} from "react-icons/md";
import Card from "../../../../components/card/Card";
import {useRecoilState} from "recoil";
import {
	emotionBranch,
	getfetchResult,
	modalControl,
	recordedAudio, skipAudioRecord,
	storeSelected
} from "../../../../recoil/atom/atomStore";
import ChakraModal from "../../chakraModal";

export default function VoiceRecord() {
	const [selectedState, setSelectedState] = useRecoilState(storeSelected);

	const token = localStorage.getItem("token");
	const [emotion, setEmotion] = useRecoilState(emotionBranch);
	const [fetchResult, setFetchResult] = useRecoilState(getfetchResult);

	const [stream, setStream] = useState();
	const [media, setMedia] = useState();
	const [onRec, setOnRec] = useState(true);
	const [source, setSource] = useState();
	const [analyser, setAnalyser] = useState();
	const [audioUrl, setAudioUrl] = useState('');
	const [audio, setAudio] = useRecoilState(recordedAudio);
	const [isPlaying, setIsPlaying] = useState(false);
	const [audioDuration, setAudioDuration] = useState(0);
	const [startTime, setStartTime] = useState(0);
	const [endTime, setEndTime] = useState(0);
	const [modal, setModal] = useRecoilState(modalControl);
	const [isSkipAudioRecord, setIsSkipAudioRecord] = useRecoilState(skipAudioRecord)

	const textColor = useColorModeValue("secondaryGray.900", "white");

	const audioRef = useRef();

	useEffect(() => {
		if (isPlaying) {
			console.log('audioDuration', audioDuration);
			console.log('start', startTime);
			console.log('end', endTime);
			setTimeout(() => {
				audio.pause();
				setIsPlaying(false);
			}, audioDuration);
		}
	}, [isPlaying]);

	useEffect(() => {
		if (audioUrl !== '') {
			// setAudio(new Audio(URL.createObjectURL(audioUrl)));
			onSubmitAudioFile();
		}
	}, [audioUrl]);

	useEffect(() => {
		setAudioDuration(endTime - startTime);
	}, [endTime]);

	const onRecAudio = () => {
		setStartTime(new Date().getTime());
		// 음원정보를 담은 노드를 생성하거나 음원을 실행또는 디코딩 시키는 일을 한다
		const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

		// 자바스크립트를 통해 음원의 진행상태에 직접접근에 사용된다.
		const analyser = audioCtx.createScriptProcessor(0, 1, 1);
		setAnalyser(analyser);

		function makeSound(stream) {
			// 내 컴퓨터의 마이크나 다른 소스를 통해 발생한 오디오 스트림의 정보를 보여준다.
			const source = audioCtx.createMediaStreamSource(stream);
			setSource(source);

			// AudioBufferSourceNode 연결
			source.connect(analyser);
			analyser.connect(audioCtx.destination);
		}

		// 마이크 사용 권한 획득 후 녹음 시작
		navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
			const mediaRecorder = new MediaRecorder(stream);
			mediaRecorder.start();
			setStream(stream);
			setMedia(mediaRecorder);
			makeSound(stream);
			// 음성 녹음이 시작됐을 때 onRec state값을 false로 변경
			analyser.onaudioprocess = function (e) {
				setOnRec(false);
			};
		});
	};

	const onSubmitAudioFile = useCallback(() => {
		// File 생성자를 사용해 파일로 변환
		const sound = new File([audioUrl], "recAudio.mp3", {lastModified: new Date().getTime(), type: "audio"});
		console.log(sound); // File 정보 출력
		setAudio(sound)
	}, [audioUrl]);

	const playMusic = () => {
		setIsPlaying(!isPlaying);
		if (!isPlaying) {
			if (audioUrl) {
				console.log(URL.createObjectURL(audioUrl)); // 출력된 링크에서 녹음된 오디오 확인 가능
				audio.play();
			}
		} else {
			audio.pause();
		}
	}

	const offRecAudio = () => {
		// dataavailable 이벤트로 Blob 데이터에 대한 응답을 받을 수 있음
		media.ondataavailable = function (e) {
			setAudioUrl(e.data);
			setOnRec(true);
		};

		// 모든 트랙에서 stop()을 호출해 오디오 스트림을 정지
		stream.getAudioTracks().forEach(function (track) {
			track.stop();
		});

		// 미디어 캡처 중지
		media.stop();

		// 메서드가 호출 된 노드 연결 해제
		analyser.disconnect();
		source.disconnect();
		setEndTime(new Date().getTime());
		setIsSkipAudioRecord(false);
		setModal(true);
	};

	return (
	  <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
		  <ChakraModal/>
		  {/* Main Fields */}
		  <Grid
			mb='20px'
			// gridTemplateColumns={{xl: "repeat(4, 1fr)", "2xl": "1fr 0.46fr"}}
			gap={{base: "20px", xl: "20px"}}
			display={{base: "block", xl: "grid"}}>
			  <Flex
				flexDirection='column'
				gridArea={{xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2"}}
			  >
				  <Flex direction='column'>
					  <Flex
						mt='45px'
						mb='2%'
						justifyContent='center'
						direction={{base: "column", md: "row"}}
						align={{base: "center", md: "center"}}>
						  <SimpleGrid columns={1} gap='0px'>
							  <Text color={textColor} align={"center"} fontSize='3xl' ms='24px' fontWeight='400'>
								  목소리를 녹음 해 주세요
							  </Text>
							  <Text color={textColor} align={"center"} fontSize='5xl' ms='24px' fontWeight='600'>
								  목소리 녹음
							  </Text>
						  </SimpleGrid>
					  </Flex>
					  <Card p='20px'>
						  <Center mt={'8'} mb={'8'}>
							  {/*<Button leftIcon={<MdKeyboardVoice/>} colorScheme="brand" variant="solid" />*/}
							  <Button
								onClick={onRec ? onRecAudio : offRecAudio}
								size={'lg'}
								height={'150px'}
								width={'150px'}
								colorScheme={'red'}
								varuant={'solid'}
								mx={'2'}
							  >
								  <SimpleGrid columns={1} gap='0px'>
									  <Center>
										  <Icon as={onRec ? MdOutlineKeyboardVoice : MdKeyboardVoice} boxSize={20}
												mb="10px"/>
									  </Center>
									  <Center>
										  <Text>
											  {onRec ? '녹음 하기' : '녹음 중지'}
										  </Text>
									  </Center>
								  </SimpleGrid>
							  </Button>
						  </Center>
						  <Flex justify='right'>
							  <Button
								onClick={() => {
									setIsSkipAudioRecord(true);
									setModal(true);
								}}
								size={'lg'}
								height={'60px'}
								colorScheme={'blackAlpha'}
								varuant={'solid'}
								mx={'2'}
							  >
								  <SimpleGrid columns={1} gap='0px'>
									  <Text>
										  녹음 건너뛰기
									  </Text>
								  </SimpleGrid>
							  </Button>
						  </Flex>
						  {/*<Center>*/}
						  {/*  <SimpleGrid columns={2} spacing={4}>*/}
						  {/*	  <Button onClick={playMusic} colorScheme={'blue'} variant={'outline'}*/}
						  {/*			  disabled={!onRec}>*/}
						  {/*		  <Text>*/}
						  {/*			  {isPlaying ? '그만 듣기' : '녹음 듣기'}*/}
						  {/*		  </Text>*/}
						  {/*	  </Button>*/}
						  {/*	  <Button colorScheme={'green'} variant={'outline'} disabled={!onRec}>*/}
						  {/*		  녹음 완료*/}
						  {/*	  </Button>*/}
						  {/*  </SimpleGrid>*/}
						  {/*</Center>*/}
					  </Card>
				  </Flex>
			  </Flex>
		  </Grid>
		  {/* Delete Product */}
	  </Box>
	);
}
