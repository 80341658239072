export default function PostFetchData(fetchArgs) {
	const {fetchUrl, fetchMethod, fetchHeader, fetchBody, fetchDispatch, fetchResult} = fetchArgs;
	console.log(fetchArgs)
	const fetchData = fetch("/api" +fetchUrl, {
		method: "POST",
		headers: fetchHeader,
		body: fetchBody
	})
	  .then(response => {
		  if (!response.ok) {
			  fetchResult("fail")
			  throw new Error(response.statusText)
		  }
		  return response.json()
	  }).catch(err => {
		  fetchResult("fail")
	  }).then(data => {
		  try {
			  console.log("data",data)
			  if (data.status === "fail") {
				  fetchResult("fail")
			  } else {
				  fetchResult("success")
				  fetchDispatch(data)
			  }
		  } catch (err) {
		  }
	  })
}
