/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

// Chakra imports
import {
	Box,
	Flex,
	Grid,
	Text,
	useColorModeValue,
	SimpleGrid,
} from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/marketplace/components/Banner";
import TableTopCreators from "views/admin/marketplace/components/TableTopCreators";
import HistoryItem from "views/admin/marketplace/components/HistoryItem";
import Attendance from "components/card/Attendance";
import Card from "components/card/Card.js";


// Assets
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";
import tableDataTopCreators from "views/admin/marketplace/variables/tableDataTopCreators.json";
import {tableColumnsTopCreators} from "views/admin/marketplace/variables/tableColumnsTopCreators";
import {useRecoilState} from "recoil";
import {
	emotionBranch,
	getClassesData,
	getfetchResult,
	getKindergartensData,
	teacherLevelState
} from "../../../recoil/atom/atomStore";
import GetFetchData from "../../../components/request/getFetchData";


export default function ClassSelect() {
	// Chakra Color Mode
	const textColor = useColorModeValue("secondaryGray.900", "white");
	const textColorBrand = useColorModeValue("brand.500", "white");
	const location = useLocation();
	const [selectEmotionState, setSelectEmotionState] = useRecoilState(emotionBranch);
	const token = localStorage.getItem("token");
	const [classData, setClassData] = useRecoilState(getClassesData);
	const [fetchResult, setFetchResult] = useRecoilState(getfetchResult);
	const [teacherLevel, setTeacherLevel] = useRecoilState(teacherLevelState);
	const fetchArgs = {
		fetchUrl: "/manage/classes",
		fetchMethod: "GET",
		fetchHeader: {
			"Content-Type": "application/json",
			"Authorization": "Token " + token
		},
		fetchDispatch: setClassData,
		fetchResult: setFetchResult
	};
	useEffect(() => {
		setClassData(null)
		GetFetchData(fetchArgs);
	}, [])

	useEffect(() => {
		if (location.pathname === "/nulbom/emotion") {
			setSelectEmotionState('emotion');
		} else if (location.pathname === "/nulbom/attendance") {
			setSelectEmotionState('attendance');
		} else {
			setSelectEmotionState('rating');
		}
	}, []);

	if (classData === null) {
		return <div>Loading...</div>
	} else {
		if (classData.data === null) {
			return <div>Loading...</div>
		} else {
			return (
			  <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
				  {/* Main Fields */}
				  <Grid
					mb='20px'
					// gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
					gap={{base: "20px", xl: "20px"}}
					display={{base: "block", xl: "grid"}}>
					  <Flex
						flexDirection='column'
						gridArea={{xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2"}}>
						  <Flex direction='column'>
							  <Flex
								mt='45px'
								mb='2%'
								justifyContent='center'
								direction={{base: "column", md: "row"}}
								align={{base: "center", md: "center"}}>
								  <SimpleGrid columns={1} gap='0px'>
									  {
										  teacherLevel ?
											<Box>
												<Text color={'red'} align={"center"} fontSize='xl' ms='24px'
													  fontWeight='400'>
													교사용 화면입니다
												</Text>
												<Text color={textColor} align={"center"} fontSize='3xl' ms='24px'
													  fontWeight='400'>
													평가할 아동의 반을 선택 해 주세요
												</Text>
											</Box> : <Text color={textColor} align={"center"} fontSize='3xl' ms='24px'
														   fontWeight='400'>
												반을 선택해 주세요
											</Text>
									  }
									  <Text color={textColor} align={"center"} fontSize='5xl' ms='24px'
											fontWeight='600'>
										  반 선택
									  </Text>
								  </SimpleGrid>
							  </Flex>
							  <SimpleGrid columns={{base: 1, md: 3}} gap='20px'>
								  {classData.data.map((item, index) => {
									  return (
										<Attendance
										  key={index}
										  name={item.name}
										  author={item.kindergarten}
										  bidders={[
											  Avatar1,
											  Avatar2,
											  Avatar3,
											  Avatar4,
											  Avatar1,
											  Avatar1,
											  Avatar1,
											  Avatar1,
										  ]}
										  image={Nft1}
										  currentbid='0.91 ETH'
										  download='#/nulbom/kid'
										  isAttend={false}
										  isClassSelect={true}
										  detailKey={item.key}
										/>
									  )
								  })}
							  </SimpleGrid>
						  </Flex>
					  </Flex>
				  </Grid>
				  {/* Delete Product */}
			  </Box>
			);
		}
	}
}
