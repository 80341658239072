/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

// Chakra imports
import {
	Box,
	Button,
	Flex,
	Grid,
	Link,
	Text,
	useColorModeValue,
	SimpleGrid, Center,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import {useRecoilState} from "recoil";
import {getfetchResult, getKindergartensData, attandanceState, evaluationState} from "../../../recoil/atom/atomStore";
import GetFetchData from "../../../components/request/getFetchData";

export default function LandingPage() {
	// Chakra Color Mode
	const textColor = useColorModeValue("secondaryGray.900", "white");
	const token = localStorage.getItem("token");
	const [kindergartenData, setKindergartenData] = useRecoilState(getKindergartensData);
	const [fetchResult, setFetchResult] = useRecoilState(getfetchResult)
	const [attendance, setAttendance] = useRecoilState(attandanceState);
	const fetchArgs = {
		fetchUrl: "/manage/schools",
		fetchMethod: "GET",
		fetchHeader: {
			"Content-Type": "application/json",
			"Authorization": "Token " + token
		},
		fetchDispatch: setKindergartenData,
		fetchResult: setFetchResult
	};
	useEffect(() => {
		GetFetchData(fetchArgs);
	}, [])
	return (
	  <Flex
		color="black" height="100%" alignContent={"center"} justifyContent={"center"} overflow={"auto"}
	  >
		  <SimpleGrid columns={2} spacing={10} width="100%" p={10}>
			  <Card mt='20%' p='20vh'
					onClick={() => {
						window.location.href = "/#/nulbom/attendance";
						setAttendance("check_in")
					}}>
				  <Center>
					  <Text fontSize="6xl" fontWeight='bold' color={textColor}>등교</Text>
				  </Center>
			  </Card>
			  <Card mt='20%' p='20vh'
					onClick={() => {
						window.location.href = "/#/nulbom/attendance";
						setAttendance("check_out")
					}}>
				  <Center>
					  <Text fontSize="6xl" fontWeight='bold' color={textColor}>하교</Text>
				  </Center>
			  </Card>
		  </SimpleGrid>
	  </Flex>
	);
}
