import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdEmojiEmotions,
  MdOutlineRadioButtonChecked,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";

import ClassSelect from "views/admin/classSelect";
import KidSelect from "views/admin/kidSelect";
import EmotionSelect from "views/admin/emotionSelect";
import KidsRating from "views/admin/kidsRating";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import LandingPage from "./views/admin/landingPage";
import VoiceRecord from "./views/admin/emotionSelect/voiceRecord";

const routes = [
  {
    name: "메인 페이지",
    layout: "/nulbom",
    path: "/default",
    hide: false,
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: LandingPage,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/nulbom",
  //   path: "/nft-marketplace",
  //   hide: false,
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/nulbom",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   hide: false,
  //   component: DataTables,
  // },
  // {
  //   name: "Profile",
  //   layout: "/nulbom",
  //   path: "/profile",
  //   hide: false,
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  // },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    hide: false,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   hide: false,
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
  {
    name: "출석",
    layout: "/nulbom",
    path: "/attendance",
    hide: false,
    icon: (
      <Icon
        as={MdPerson}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: KidSelect,
    secondary: true,
  },
  {
    name: "아동선택",
    layout: "/nulbom",
    path: "/kid",
    hide: true,
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: KidSelect,
    secondary: true,
  },
  {
    name: "감정 선택",
    layout: "/nulbom",
    path: "/emotion",
    hide: false,
    icon: (
      <Icon
        as={MdEmojiEmotions}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: ClassSelect,
    secondary: true,
  },
  {
    name: "emotionSelect",
    layout: "/nulbom",
    path: "/emotion-select",
    hide: true,
    icon: (
      <Icon
        as={MdEmojiEmotions}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: EmotionSelect,
    secondary: true,
  },  {
    name: "emotionSelect",
    layout: "/nulbom",
    path: "/voice-record",
    hide: true,
    icon: (
      <Icon
        as={MdEmojiEmotions}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: VoiceRecord,
    secondary: true,
  },
  {
    name: "아동평가",
    layout: "/nulbom",
    path: "/kidsrating",
    hide: false,
    icon: (
      <Icon
        as={MdOutlineRadioButtonChecked}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: KidsRating,
    secondary: true,
  },
  {
    name: "아동평가",
    layout: "/nulbom",
    path: "/kidsratingpage",
    hide: true,
    icon: (
      <Icon
        as={MdEmojiEmotions}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: KidsRating,
    secondary: true,
  },
];

export default routes;
