import React, {useState} from 'react';
import {Box, Flex, Select, SimpleGrid} from "@chakra-ui/react";

export default function DatePicker(props) {
	const {getYear, getMonth, getDay, getYChanged, getMChanged, getDChanged} = props;
	const [year, setYear] = useState(0);
	const [month, setMonth] = useState(0);
	const [day, setDay] = useState(0);

	const handleYear = (data) => {
		setYear(data);
		getYear(data);
		getYChanged(true)
	}
	const handleMonth = (data) => {
		setMonth(data);
		getMonth(data);
		getMChanged(true)
	}
	const handleDay = (data) => {
		setDay(data);
		getDay(data);
		getDChanged(true)
	}

	return (
	  <Box>
		  <Flex ml={'5'} mb={4}>
			  <SimpleGrid columns={3} spacing={4}>
				  <Box>
					  <Select placeholder="년도 선택" value={year} onChange={(e) => handleYear(e.target.value)}>
						  <option value="2021">2021년</option>
						  <option value="2022">2022년</option>
						  <option value="2023">2023년</option>
						  <option value="2024">2024년</option>
					  </Select>
				  </Box>
				  <Box>
					  <Select placeholder="월 선택" value={month} onChange={(e) => handleMonth(e.target.value)}>
						  <option value="01">1월</option>
						  <option value="02">2월</option>
						  <option value="03">3월</option>
						  <option value="04">4월</option>
						  <option value="05">5월</option>
						  <option value="06">6월</option>
						  <option value="07">7월</option>
						  <option value="08">8월</option>
						  <option value="09">9월</option>
						  <option value="10">10월</option>
						  <option value="11">11월</option>
						  <option value="12">12월</option>
					  </Select>
				  </Box>
				  <Box>
					  <Select placeholder="일 선택" value={day} onChange={(e) => handleDay(e.target.value)}>
						  <option value="01">1일</option>
						  <option value="02">2일</option>
						  <option value="03">3일</option>
						  <option value="04">4일</option>
						  <option value="05">5일</option>
						  <option value="06">6일</option>
						  <option value="07">7일</option>
						  <option value="08">8일</option>
						  <option value="09">9일</option>
						  <option value="10">10일</option>
						  <option value="11">11일</option>
						  <option value="12">12일</option>
						  <option value="13">13일</option>
						  <option value="14">14일</option>
						  <option value="15">15일</option>
						  <option value="16">16일</option>
						  <option value="17">17일</option>
						  <option value="18">18일</option>
						  <option value="19">19일</option>
						  <option value="20">20일</option>
						  <option value="21">21일</option>
						  <option value="22">22일</option>
						  <option value="23">23일</option>
						  <option value="24">24일</option>
						  <option value="25">25일</option>
						  <option value="26">26일</option>
						  <option value="27">27일</option>
						  <option value="28">28일</option>
						  <option value="29">29일</option>
						  <option value="30">30일</option>
						  <option value="31">31일</option>
					  </Select>
				  </Box>
			  </SimpleGrid>
		  </Flex>
	  </Box>
	)
}
