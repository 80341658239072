export default function GetFetchData(fetchArgs) {
	const {fetchUrl, fetchMethod, fetchHeader, fetchDispatch, fetchResult} = fetchArgs;
	console.log(fetchHeader)
	const fetchData = fetch("/api" +fetchUrl, {
		method: "GET",
		headers: {
			Authorization: fetchHeader.Authorization
		}
	})
	  .then(response => {
		  if (!response.ok) {
			  console.log(response)
			  if (response.status === 401) {
				  localStorage.removeItem("token")
				  window.location.href = "/#/auth/sign-in/default";
			  }
			  fetchResult(response.status)
			  throw new Error(response.statusText)
		  }
		  return response.json()
	  }).catch(err => {
		  // console.log(err)
	  }).then(data => {
		  console.log(data)
		  fetchDispatch(data)
	  })
}


