/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useEffect} from "react";

// Chakra imports
import {
	Box,
	Button,
	Flex,
	Grid,
	Link,
	Text,
	useColorModeValue,
	SimpleGrid,
} from "@chakra-ui/react";

// Custom components
import Attendance from "components/card/Attendance";

// Assets
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";

import styled from '@emotion/styled';
import {
	Happy,
	Sad,
	Smile,
} from "@emotion-icons/boxicons-regular";

import {
	SadTear,
	SadCry,
	Angry
} from "@emotion-icons/fa-regular"

import {
	EmotionUnhappy
} from "@emotion-icons/remix-line"

export default function EmotionSelect() {
	// Chakra Color Mode
	const textColor = useColorModeValue("secondaryGray.900", "white");
	const textColorBrand = useColorModeValue("brand.500", "white");

	return (
	  <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
		  {/* Main Fields */}
		  <Grid
			mb='20px'
			// gridTemplateColumns={{xl: "repeat(4, 1fr)", "2xl": "1fr 0.46fr"}}
			gap={{base: "20px", xl: "20px"}}
			display={{base: "block", xl: "grid"}}>
			  <Flex
				flexDirection='column'
				gridArea={{xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2"}}
			  >
				  <Flex direction='column'>
					  <Flex
						mt='45px'
						mb='2%'
						justifyContent='center'
						direction={{base: "column", md: "row"}}
						align={{base: "center", md: "center"}}>
						  <SimpleGrid columns={1} gap='0px'>
							  <Text color={textColor} align={"center"} fontSize='3xl' ms='24px' fontWeight='400'>
								  나의 감정을 선택해 주세요
							  </Text>
							  <Text color={textColor} align={"center"} fontSize='5xl' ms='24px' fontWeight='600'>
								  감정 선택
							  </Text>
						  </SimpleGrid>
					  </Flex>
					  <SimpleGrid columns={{base: 1, md: 3}} gap='20px'>
						  <Attendance
							name='행복해요'
							author='By Esthera Jackson'
							bidders={[
								Avatar1,
								Avatar2,
								Avatar3,
								Avatar4,
								Avatar1,
								Avatar1,
								Avatar1,
								Avatar1,
							]}
							image={Nft1}
							currentbid='0.91 ETH'
							download='#'
							isAttend={false}
							isClassSelect={false}
							emotion=<Happy size='100' color='#1b254b'/>
						  />
						  <Attendance
							name='신나요'
							author='By Nick Wilson'
							bidders={[
								Avatar1,
								Avatar2,
								Avatar3,
								Avatar4,
								Avatar1,
								Avatar1,
								Avatar1,
								Avatar1,
							]}
							image={Nft2}
							currentbid='0.91 ETH'
							download='#'
							isAttend={false}
							isClassSelect={false}
							emotion=<Smile size='100'color='#1b254b'/>
						  />
						  <Attendance
							name='화나요'
							author='By Will Smith'
							bidders={[
								Avatar1,
								Avatar2,
								Avatar3,
								Avatar4,
								Avatar1,
								Avatar1,
								Avatar1,
								Avatar1,
							]}
							image={Nft3}
							currentbid='0.91 ETH'
							download='#'
							isAttend={false}
							isClassSelect={false}
							emotion=<Angry size='90'color='#1b254b'/>
						  />
						  <Attendance
							name='슬퍼요'
							author='By Will Smith'
							bidders={[
								Avatar1,
								Avatar2,
								Avatar3,
								Avatar4,
								Avatar1,
								Avatar1,
								Avatar1,
								Avatar1,
							]}
							image={Nft3}
							currentbid='0.91 ETH'
							download='#'
							isAttend={false}
							isClassSelect={false}
							emotion=<SadTear size='90'color='#1b254b'/>
						  />
						  <Attendance
							name='외로워요'
							author='By Will Smith'
							bidders={[
								Avatar1,
								Avatar2,
								Avatar3,
								Avatar4,
								Avatar1,
								Avatar1,
								Avatar1,
								Avatar1,
							]}
							image={Nft3}
							currentbid='0.91 ETH'
							download='#'
							isAttend={false}
							isClassSelect={false}
							emotion=<EmotionUnhappy size='100'color='#1b254b'/>
						  />
						  <Attendance
							name='걱정돼요'
							author='By Will Smith'
							bidders={[
								Avatar1,
								Avatar2,
								Avatar3,
								Avatar4,
								Avatar1,
								Avatar1,
								Avatar1,
								Avatar1,
							]}
							image={Nft3}
							currentbid='0.91 ETH'
							download='#'
							isAttend={false}
							isClassSelect={false}
							emotion=<Sad size='100'color='#1b254b'/>
						  />
					  </SimpleGrid>
				  </Flex>
			  </Flex>
		  </Grid>
		  {/* Delete Product */}
	  </Box>
	);
}
