import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton, Center, Box, Text, Flex, Button, Link,
} from '@chakra-ui/react'

import {
	attandanceState,
	emotionBranch,
	getAttendResult,
	getfetchResult,
	recordedAudio, skipAudioRecord,
	storeSelected,
	teacherLevelState
} from "../../../recoil/atom/atomStore";
import React, {useEffect, useState} from 'react'
import {useRecoilState} from "recoil";
import {modalControl} from "../../../recoil/atom/atomStore";
import {useLocation} from "react-router-dom";
import PostFetchData from "../../../components/request/postFetchData";

export default function ChakraModal({props}) {
	const token = localStorage.getItem("token");
	const [modal, setModal] = useRecoilState(modalControl);
	const [selectedState, setSelectedState] = useRecoilState(storeSelected);
	const [emotionBranchState, setEmotionBranchState] = useRecoilState(emotionBranch);
	const [attendResult, setAttendResult] = useRecoilState(getAttendResult)
	const [fetchResult, setFetchResult] = useRecoilState(getfetchResult)
	const [fetchAudio, setFetchAudio] = useRecoilState(recordedAudio)
	const [modalComment, setModalComment] = useState("");
	const [isError, setIsError] = useState(false);
	const [isAttend, setIsAttend] = useState(false);
	const [url, setUrl] = useState("");
	const [isSendingAttend, setIsSendingAttend] = useState(false);
	const [teacherLevel, setTeacherLevel] = useRecoilState(teacherLevelState);
	const [isSkipAudioRecord, setIsSkipAudioRecord] = useRecoilState(skipAudioRecord)
	const [attendance, setAttendance] = useRecoilState(attandanceState);

	const nowLocation = useLocation();
	useEffect(() => {
		setModal(false)
		setIsError(false)
		handleUrl();
	}, []);

	const handleUrl = () => {
		if (nowLocation.pathname === "/nulbom/attendance") {
			setUrl("#/nulbom/emotion-select");
		} else if (nowLocation.pathname === '/nulbom/kid') {
			if (emotionBranchState === "rating") {
				setUrl("#/nulbom/kidsratingpage");
			} else {
				setUrl("#/nulbom/emotion-select");
				setIsAttend(true);
			}
		}
	}

	useEffect(() => {
		if (nowLocation.pathname === "/nulbom/emotion-select") {
			setModalComment("우리 친구는 지금 " + selectedState.emotionName + "?");
		} else if (nowLocation.pathname === "/nulbom/attendance" ||
		  nowLocation.pathname === "/nulbom/emotion" || nowLocation.pathname === "/nulbom/kidsrating") {
			if (teacherLevel) {
				setModalComment(selectedState.className);
			} else {
				setModalComment(selectedState.className + " 학생인가요?");
			}
		} else {
			if (teacherLevel) {
				setModalComment(selectedState.kidName);
			} else {
				setModalComment(selectedState.kidName + " 학생인가요?");
			}
		}
	}, [selectedState]);

	useEffect(() => {
		if (isSkipAudioRecord) {
			setModalComment("녹음을 건너뜁니다");
		} else {
			setModalComment("녹음이 잘 되었나요?");
		}
	}, [isSkipAudioRecord])

	const submitEmotion = () => {
		let fetchEmotion = ''
		switch (selectedState.emotionName) {
			case "행복해요":
				fetchEmotion = "happy"
				break;
			case "슬퍼요":
				fetchEmotion = "sad"
				break;
			case "화나요":
				fetchEmotion = "angry"
				break;
			case "신나요":
				fetchEmotion = "joy"
				break;
			case "외로워요":
				fetchEmotion = "lonely"
				break;
			case "걱정돼요":
				fetchEmotion = "worry"
				break;
			default:
				fetchEmotion = ""
				break;
		}

		const audioFile = new File([fetchAudio], "recAudio.mp3", {
			lastModified: new Date().getTime(),
			type: "audio/mpeg",
		});
		console.log("fetchAudio", fetchAudio)
		// console.log("audioFile", audioFile)

		const emotionFormData = new FormData();
		console.log(selectedState.kidKey)
		if (isSkipAudioRecord) {
			emotionFormData.append("key", selectedState.kidKey);
			emotionFormData.append('emotion', fetchEmotion);
			emotionFormData.append('state', attendance);
			emotionFormData.append('rec_audio', null);
		} else {
			emotionFormData.append("key", selectedState.kidKey);
			emotionFormData.append('emotion', fetchEmotion);
			emotionFormData.append('state', attendance);
			emotionFormData.append('rec_audio', fetchAudio);
		}


		const emotionFetchArgs = {
			fetchUrl: "/manage/attendance",
			fetchMethod: "POST",
			fetchHeader: {
				// "Content-Type": "multipart/form-data",
				"Authorization": "Token " + token
			},
			fetchBody: emotionFormData,
			fetchDispatch: setAttendResult,
			fetchResult: setFetchResult
		}
		PostFetchData(emotionFetchArgs)
		// handleRequest();
		setModal(false)
		window.location.href = "#";
	}

	const handleRequest = () => {
		const formData = new FormData();
		formData.append("key", selectedState.kidKey);
		formData.append('state', attendance);

		const fetchArgs = {
			fetchUrl: "/manage/attendance",
			fetchMethod: "POST",
			fetchHeader: {
				// "Content-Type": "multipart/form-data",
				"Authorization": "Token " + token
			},
			fetchBody: formData,
			fetchDispatch: setAttendResult,
			fetchResult: setFetchResult
		};
		handleAttend(fetchArgs);
	};

	const handleAttend = (fetchArgs) => {
		PostFetchData(fetchArgs)
		setModal(false)
	}

	const handleClose = () => {
		console.log("close")
		setModal(false);
	}
	return (
	  <Modal isOpen={modal} onClose={handleClose}>
		  <ModalOverlay/>
		  <Center>
			  <ModalContent my={'auto'}>
				  <ModalCloseButton/>
				  <ModalBody>
					  <Flex mt='20px'>
						  <Box flex='1' justifyContent='center'>
							  <Center>
								  <Text fontSize='xl' fontWeight='bold' color='navy.700'>
									  {modalComment}
								  </Text>
							  </Center>
						  </Box>
					  </Flex>
					  {
						  nowLocation.pathname === "/nulbom/voice-record" ?
							<Flex mt='5' mb={1}>
								<Box flex='1' justifyContent='center'>
									<Center>
										<Button onClick={submitEmotion} colorScheme='green' size='lg'>
											맞아요
										</Button>
									</Center>
								</Box>
								<Box flex='1' justifyContent='center'>
									<Center>
										<Button onClick={handleClose} colorScheme='red' size='lg'>
											아니요
										</Button>
									</Center>
								</Box>
							</Flex>
							:
							<Flex mt='5' mb={1}>
								<Box flex='1' justifyContent='center'>
									<Center>
										<Link href={url}>
											<Button onClick={handleClose} colorScheme='green' size='lg'>
												맞아요
											</Button>
										</Link>
									</Center>
								</Box>
								<Box flex='1' justifyContent='center'>
									<Center>
										<Button onClick={handleClose} colorScheme='red' size='lg'>
											아니요
										</Button>
									</Center>
								</Box>
							</Flex>
					  }
				  </ModalBody>
				  <ModalFooter>
					  {/* <Button colorScheme="blue" mr={3} onClick={onClose}>
						Close
					</Button>
					<Button variant="ghost">Secondary Action</Button> */}
				  </ModalFooter>
			  </ModalContent>
		  </Center>
	  </Modal>
	)
}
