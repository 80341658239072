import React from 'react';
import {useEffect, useState} from 'react';
import Card from "components/card/Card.js";
import {
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer, Grid, Flex, Text, SimpleGrid, Box, useColorModeValue, Button, Center
} from '@chakra-ui/react'

import {StarIcon} from '@chakra-ui/icons'
import DatePicker from "../../../components/datePicker";
import {useRecoilState} from "recoil";
import {
	evaluationState,
	getAttendResult,
	getfetchResult,
	storeSelected,
	teacherLevelState
} from "../../../recoil/atom/atomStore";
import PostFetchData from "../../../components/request/postFetchData";
import GetFetchData from "../../../components/request/getFetchData";
import useDidMountEffect from "../../../components/useDidMountEffect";
import PatchFetchData from "../../../components/request/patchFetchData";

export default function KidsRating() {

	const token = localStorage.getItem("token");
	const textColor = useColorModeValue("secondaryGray.900", "white");
	const starActive = useColorModeValue("yellow.300", "yellow.300");
	const starDeactive = useColorModeValue("gray.300", "gray.300");
	const [selectedState, setSelectedState] = useRecoilState(storeSelected);
	const [attendResult, setAttendResult] = useRecoilState(getAttendResult)
	const [fetchResult, setFetchResult] = useRecoilState(getfetchResult)
	const [teacherLevel, setTeacherLevel] = useRecoilState(teacherLevelState);
	const [evaluation, setEvaluation] = useRecoilState(evaluationState)
	const [kidArray, setKidArray] = useState([]);
	const [kidData, setKidData] = useState({
		"child_name": "",
		"key": "",
		"eval_date": "",
		"integrity": 0,
		"activeness": 0,
		"concentrativeness": 0
	});
	const [year, setYear] = useState(0);
	const [month, setMonth] = useState(0);
	const [day, setDay] = useState(0);
	const [yearChanged, setYearChanged] = useState(false);
	const [monthChanged, setMonthChanged] = useState(false);
	const [dayChanged, setDayChanged] = useState(false);
	const [evalDate, setEvalDate] = useState("")

	const GenerateKidsArray = (data) => {
		console.log("data", data);
		const kidsArrayTmp = [];
		const kidJsonForm = {
			"child_name": "",
			"key": "",
			"eval_date": "",
			"integrity": 0,
			"activeness": 0,
			"concentrativeness": 0
		}
		if (!data) return;
		for (let i = 0; i < data.data.length; i++) {
			if (data.data[i].eval.length === 0) {
				kidJsonForm["child_name"] = data.data[i].name;
				kidJsonForm["key"] = data.data[i].key;
				kidJsonForm["eval_date"] = "";
				kidJsonForm["integrity"] = 0;
				kidJsonForm["activeness"] = 0;
				kidJsonForm["concentrativeness"] = 0;
				const arrayDeepCopy = JSON.parse(JSON.stringify(kidJsonForm));
				kidsArrayTmp.push(arrayDeepCopy);
			} else {
				kidJsonForm["child_name"] = data.data[i].name;
				kidJsonForm["key"] = data.data[i].key;
				kidJsonForm["eval_date"] = data.data[i].eval[0].eval_date;
				kidJsonForm["integrity"] = data.data[i].eval[0].integrity === null ? 0 : data.data[i].eval[0].integrity
				kidJsonForm["activeness"] = data.data[i].eval[0].activeness === null ? 0 : data.data[i].eval[0].activeness;
				kidJsonForm["concentrativeness"] = data.data[i].eval[0].concentrativeness === null ? 0 : data.data[i].eval[0].concentrativeness;
				const arrayDeepCopy = JSON.parse(JSON.stringify(kidJsonForm));
				kidsArrayTmp.push(arrayDeepCopy);
			}
		}
		console.log("kidsArray", kidsArrayTmp);
		setKidArray(kidsArrayTmp);
	}

	useEffect(() => {
		GenerateKidsArray(evaluation);
		console.log("evaluation", evaluation);
	}, [evaluation]);

	const getEvaluation = () => {
		const fetchArgs = {
			fetchUrl: "/check/eval?date=" + evalDate,
			fetchMethod: "GET",
			fetchHeader: {
				"Content-Type": "application/json",
				"Authorization": "Token " + token
			},
			fetchDispatch: setEvaluation,
			fetchResult: setFetchResult
		};
		GetFetchData(fetchArgs);
	}

	useDidMountEffect(() => {
		getEvaluation();
	}, [evalDate]);

	const handleRate = (kidKey, rate, rateNum) => {
		setKidArray(
		  kidArray.map((data) => {
			  if (data.key === kidKey) {
				  return {
					  ...data,
					  [rateNum]: rate
				  }
			  }
			  return data;
		  })
		)
		fetchKidData(kidKey, rate, rateNum);
	}
	useEffect(
	  () => {
		  console.log("kidArray", kidArray);
	  }, [kidArray]
	)
	const handleDate = (year, month, day) => {
		setKidData(
		  {
			  ...kidData,
			  "eval_date": year + "-" + month + "-" + day
		  }
		)
		setEvalDate(year + "-" + month + "-" + day)
		setYearChanged(false);
		setMonthChanged(false);
		setDayChanged(false);
	}
	useEffect(() => {
		setKidData(
		  {
			  ...kidData,
			  "child_name": selectedState.kidName
		  }
		)
	}, []);

	useEffect(() => {
		console.log("DATECHANGED", yearChanged, monthChanged, dayChanged);
		// if (yearChanged && monthChanged && dayChanged) {
		handleDate(year, month, day);
		// }
	}, [year, month, day]);

	useEffect(() => {
		console.log(kidData);
	}, [kidData]);

	const handleYear = (data) => {

		if (data.length < 2) {
			setYear(0);
		} else {
			setYear(data);
		}
	}
	const handleMonth = (data) => {
		if (data.length < 2) {
			setMonth(0);
		} else {
			setMonth(data);
		}
	}
	const handleDay = (data) => {
		if (data.length < 2) {
			setDay(0);
		} else {
			setDay(data);
		}
	}

	const fetchKidData = (key, value, type) => {
		const fetchArgs = {
			fetchUrl: "/check/eval",
			fetchMethod: "PATCH",
			fetchHeader: {
				"Content-Type": "application/json",
				"Authorization": "Token " + token
			},
			fetchBody: JSON.stringify({
				"key": key,
				"eval_date": evalDate,
				"type": type,
				"value": value
			}),
			fetchDispatch: setAttendResult,
			fetchResult: setFetchResult
		};
		PatchFetchData(fetchArgs)

	}
	return (
	  <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
		  {/* Main Fields */}
		  <Card p='20px'>
			  <Grid
				mb='20px'
				// gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
				gap={{base: "20px", xl: "20px"}}
				display={{base: "block", xl: "grid"}}>
				  <Flex
					flexDirection='column'
					gridArea={{xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2"}}>
					  <Flex direction='column'>
						  <Flex
							mt='45px'
							mb='20px'
							justifyContent='space-between'
							direction={{base: "column", md: "row"}}
							align={{base: "start", md: "center"}}>
							  <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
								  아동 평가
							  </Text>
						  </Flex>
						  <Flex direction='column'>
							  <DatePicker getYear={handleYear} getMonth={handleMonth} getDay={handleDay}
										  getYChanged={setYearChanged} getMChanged={setMonthChanged}
										  getDChanged={setDayChanged}/>
						  </Flex>
						  {
							  evaluation === null ?
								<Flex ml={'5'} mb={5} mt={5}>
									<SimpleGrid columns={3} spacing={4}>
										<Text>평가 데이터가 없습니다.</Text>
									</SimpleGrid>
								</Flex>
								:
								<TableContainer>
									<Table variant="simple">
										{year === 0 || month === 0 || day === 0 ?
										  null
										  :
										  <Thead>
											  <Tr>
												  <Th>아이 이름</Th>
												  <Th>성실도</Th>
												  <Th>적극성</Th>
												  <Th>집중도</Th>
											  </Tr>
										  </Thead>
										}
										{
											kidArray.length === 0 ?
											  <Flex>
												  <SimpleGrid>
													  <Box>
														  {year === 0 || month === 0 || day === 0 ?
															<Text ml={'5'}>날짜를 선택해 주세요</Text> : null}
													  </Box>
												  </SimpleGrid>
											  </Flex>
											  :
											  <Tbody>
												  {evaluation.data.map((data, index) => {
													  return (
														<Tr key={index}>
															<Td>{data.name}</Td>
															<Td>
																<SimpleGrid columns={5}>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 1, "integrity");
																		  console.log(kidArray[index].integrity);
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].integrity >= 1 ? starActive : starDeactive}
																	/>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 2, "integrity");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].integrity >= 2 ? starActive : starDeactive}
																	/>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 3, "integrity");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].integrity >= 3 ? starActive : starDeactive}
																	/>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 4, "integrity");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].integrity >= 4 ? starActive : starDeactive}
																	/>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 5, "integrity");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].integrity >= 5 ? starActive : starDeactive}
																	/>
																</SimpleGrid>
															</Td>
															<Td>
																<SimpleGrid columns={5}>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 1, "activeness");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].activeness >= 1 ? starActive : starDeactive}
																	/>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 2, "activeness");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].activeness >= 2 ? starActive : starDeactive}
																	/>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 3, "activeness");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].activeness >= 3 ? starActive : starDeactive}
																	/>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 4, "activeness");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].activeness >= 4 ? starActive : starDeactive}
																	/>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 5, "activeness");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].activeness >= 5 ? starActive : starDeactive}
																	/>
																</SimpleGrid>
															</Td>
															<Td>
																<SimpleGrid columns={5}>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 1, "concentrativeness");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].concentrativeness >= 1 ? starActive : starDeactive}
																	/>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 2, "concentrativeness");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].concentrativeness >= 2 ? starActive : starDeactive}
																	/>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 3, "concentrativeness");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].concentrativeness >= 3 ? starActive : starDeactive}
																	/>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 4, "concentrativeness");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].concentrativeness >= 4 ? starActive : starDeactive}
																	/>
																	<StarIcon
																	  onClick={() => {
																		  handleRate(data.key, 5, "concentrativeness");
																	  }}
																	  boxSize={10}
																	  color={kidArray[index].concentrativeness >= 5 ? starActive : starDeactive}
																	/>
																</SimpleGrid>
															</Td>
														</Tr>
													  )
												  })}
											  </Tbody>
										}
									</Table>
								</TableContainer>
						  }
					  </Flex>
				  </Flex>
			  </Grid>
		  </Card>
	  </Box>
	)


}
